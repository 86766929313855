var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c99653a19e884925b0073342558ddb5672f4bd58"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { CONFIG } from 'config/env.config';
import { CaptureConsole } from '@sentry/integrations';

const getBreadcrumbDataField = (
  fieldName: string,
  data?: Record<string, string>
): string => {
  if (data && fieldName in data && typeof data[fieldName] === 'string')
    return data[fieldName];

  return '';
};

const gatewayHostname = new URL(CONFIG.NEXT_PUBLIC_GATEWAY_BASE_URL).hostname;

console.log(
  'Sentry client config applied with target environment:',
  CONFIG.NEXT_PUBLIC_TARGET_ENVIRONMENT
);
Sentry.init({
  dsn:
    CONFIG.NEXT_PUBLIC_SENTRY_DSN !== ''
      ? CONFIG.NEXT_PUBLIC_SENTRY_DSN
      : undefined,
  environment: CONFIG.NEXT_PUBLIC_TARGET_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserTracing({
      // TODO: rename the field to tracePropagationTargets after upgrading Sentry to a latest version
      tracePropagationTargets: ['localhost', /^\//, gatewayHostname],
    }),
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
    new CaptureConsole({
      levels: ['error'],
    }),
  ],
  ignoreErrors: ['401'],
  beforeBreadcrumb: (breadcrumb /*, hint*/) => {
    const data = breadcrumb.data;
    if (
      data &&
      breadcrumb.category === 'xhr' &&
      getBreadcrumbDataField('url', breadcrumb.data).includes(
        'rs.fullstory.com'
      )
    ) {
      return null;
    }
    return breadcrumb;
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: parseFloat(CONFIG.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE),
  profilesSampleRate: parseFloat(
    CONFIG.NEXT_PUBLIC_SENTRY_PROFILES_SAMPLE_RATE
  ),
});
